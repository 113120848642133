import React, { useState } from 'react'
import "../style/faq.less"
import { navigate } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import open from "../images/home/open.png"
import close from "../images/home/close.png"

export default function Faq() {
    const [listShow, setListShow] = useState(null)
    const list = [
        {title:"What is a Datatrust?",text:"   A Trust in the traditional sense of the word is a three-party relationship in which an asset of value is transferred from a Grantor to a Beneficiary through a Trustee. This 3 “node” network is based on a trusted relationship between the parties so assets can be accessed/shared/transferred as per the governance rules of the trust. Sightline takes this concept further to define a Datatrust as follows: A Datatrust establishes a governance framework with an architecture that supports the legal and technological infrastructure to ensure trust over the data and derivative data assets throughout their lifecycle. It provides a legally binding framework for stewardship over the data assets for the benefit of the people or the group of organizations within the Trust."},
        {title:"How do I know if DTaaS is the right solution for my use case?",text:"Our Solutions page showcases some common applications of DTaaS in Healthcare, Smart Cities and Financial Services. DTaaS is a great solution for other industries as well. Please get in touch with us through the contact form to speak with one of our product specialists who can share more about the potential application of DTaaS to your specific industry and use case."},
        {title:"Can I demo DTaaS?",text:"We would be more than happy to demo DTaaS for you. Please get in touch with us through the contact form and one of our product specialists will contact you within 24 hours."}
    ]
    const controlList = (index)=>{
        setListShow(index)
    }
    return (
        <>
        <div className='faq'>
            <Header />
            <div className='search'>
                <h3>Hi, how can we help?</h3>
                <input placeholder='Search for answers' type="text" />
            </div>
            <ul className='faq_content'>
                {
                    list.map((item, index) => {
                        return (
                            <li style={{border:listShow===index?"1px solid #1964FB":"none"}} key={index}>
                                <div className='title' style={{borderBottom:listShow===index?"1px solid #1964FB":"none"}} >
                                    <h3>{item.title}</h3>
                                    <div>
                                        {
                                            listShow===index?
                                            <img  onClick={()=>controlList(null)} src={close} alt=""/>:
                                            <img  onClick={()=>controlList(index)} src={open} alt="" />
                                        }
                                    </div>
                                </div>
                                <div className='content' style={{display:listShow===index?"block":"none"}}>
                                        {item.text}
                                  </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        
        <Footer />
        </>
    )
}
